<template>
  <div class="run-lottery">
    <van-nav-bar :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('lottery.lotteryForum') }}</span>
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="isRefreshLoading" :loosing-text="$t('home.releaseToRefresh')"
      :loading-text="this.$t('common.loading')" :pulling-text="$t('common.dropDownRefresh')" @refresh="onRefresh">
      <div class="lottery-item" v-for="(item, index) in lotteryList" :key="index" @click="goLotteryDetail(item)">
        <div class="lottery-left">
          <img class="lottery-img" :src="item.picture" alt="" />
          <div class="lottery-info">
            <h3 class="lottery-info-title">{{ item.title }}</h3>
            <div class="lottery-info-expect" v-if="item.lastinfo.expect">
              {{ $t('lottery.session') }} {{ item.lastinfo.expect }}
            </div>
            <div class="lottery-info-code" v-if="item.type !== 'hl'">
              <template v-for="(code, index1) in item.lastinfo.codes">
                <span class="lottery-info-code-text" :key="index1" v-if="index1 !== item.lastinfo.code.length">{{ code
                }}</span>
              </template>
            </div>
            <div class="lottery-info-code" v-else>
              <template v-for="(code, index1) in item.lastinfo.code">
                <span class="lottery-info-code-text" :key="index1" v-if="index1 !== item.lastinfo.code.length">{{ code
                }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="lottery-right">
          <div class="lottery-time">
            {{ (item.lastinfo.opentime * 1000) | calendar }}
          </div>
          <van-icon name="arrow" size="0.4rem" />
        </div>
      </div>
    </van-pull-refresh>
    <div class="kong"></div>
  </div>
</template>

<script>
import { downloadApp } from '@/utils/downloadUrl'
import { Dialog } from 'vant'
import { getLotteryType } from '@/api/lottery.js'
import { isAppIos } from '@/utils/unique'
export default {
  name: '',
  components: {},
  data() {
    return {
      lotteryList: [],
      isRefreshLoading: false
    }
  },
  mounted() {
    this.lotteryListWatch()
  },
  created() {
    this.getLotteryListHandler()
  },
  methods: {
    lotteryListWatch() {
      var that = this
      const timer2 = window.setInterval(() => {
        setTimeout(() => {
          that.getLotteryListHandler()
        }, 0)
      }, 10000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer2)
      })
    },
    async getLotteryListHandler() {
      const {
        data: { data, ret }
      } = await getLotteryType({ lastinfo: 1 })
      this.isRefreshLoading = false
      if (ret === 1) {
        this.lotteryList = data
      }
    },
    onRefresh() {
      this.isRefreshLoading = true
      this.getLotteryListHandler()
    },
    goLotteryDetail(data) {
      const list = [
        'sfssc',
        'txffc',
        'sfpk10',
        'xyft',
        'jnd28',
        'xy28',
        'cqssc'
      ]
      const isGo = list.some((_) => {
        return _ === data.type
      })
      if (data.type === 'hl') {
        this.$store.commit('setLottery', data)
        this.$router.push({
          path: '/lottery/detail'
        })
        return false
      } else if (isGo || true) {
        this.$store.commit('setLottery', data)
        this.$router.push({
          path: '/lottery/detail'
        })
        return false
      } else {
        Dialog.confirm({
          title: this.$t('lottery.notification'),
          message:
            this.$t('lottery.lotteryText'),
          cancelButtonText: this.$t('common.cancel'),
          confirmButtonText: this.$t('common.confirm'),
        })
          .then(() => {
            if (isAppIos()) {
              this.$toast(this.$t('login.APPOnlySupportsAndroidUsersDownload'))
              return false
            }
            window.location.href =
              localStorage.getItem('appurl_android') || downloadApp
          })
          .catch(() => {
            // on cancel
          })
        return false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.run-lottery {
  // padding-top: 100px;
  min-height: calc(100% - 100px);
  background-color: #fff;

  .lottery-title {
    width: 100%;
    font-family: 'PingFang-Medium';
    font-size: 24px;
    color: #ff0000;
    text-align: center;

    border-bottom: 1px solid #efefef;

    .title {
      display: inline-block;

      i {
        padding: 18px 0;
        font-style: normal;
        display: inline-block;
        height: 100%;
        border-bottom: 2px solid #ff0000;
      }
    }
  }

  .lottery-item {
    min-height: 160px;
    padding: 15px;
    // background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    position: relative;

    .lottery-left {
      max-width: 650px;
      display: flex;
      align-items: center;

      .lottery-img {
        margin-right: 15px;
        width: 90px;
        height: 90px;
      }

      .lottery-info {
        font-family: 'PingFang-Regular';
        display: flex;
        flex-direction: column;
        justify-content: center;

        .lottery-info-title {
          font-weight: normal;
          font-size: 30px;
          color: #242424;
        }

        .lottery-info-expect {
          color: #646464;
          font-size: 24px;
          padding: 2px 0;
        }

        .lottery-info-code {
          .lottery-info-code-text {
            display: inline-block;
            width: 45px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            margin-right: 7px;
            color: #ffffff;
            font-size: 26px;
            border-radius: 50%;
            background-color: #4800ff;
            background-color: #dc1323;
          }

          img {
            width: 50px;
            height: 50px;
            vertical-align: bottom;
            padding-bottom: 7px;
          }
        }
      }
    }

    .lottery-right {
      flex-direction: column;
      align-items: flex-end;
      display: flex;
      position: absolute;
      right: 15px;

      .lottery-time {
        font-family: 'PingFang-Regular';
        color: #646464;
        font-size: 24px;
      }

      .van-icon-arrow {
        padding-top: 40px;
        color: #242424;
      }
    }
  }

  >.kong {
    height: 100px;
  }
}
</style>
